import React, { FunctionComponent, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { IonText } from "@ionic/react";

export const Authenticate: FunctionComponent<{
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}> = ({ setIsAuthenticated }) => {
  const { search } = useLocation();
  console.log(search);
  const token = queryString.parse(search).token;
  // const history = useHistory();

  useEffect(() => {
    const authenticate = async () => {
      try {
        const response = await fetch(`/api/stytch?token=${token}`);
        const data = await response.json();
        if (response.ok && data.session_token) {
          console.log(
            `Authenticated as: ${data.session.authentication_factors[0].email_factor.email_address}`
          );
          // TODO: Add database call to get user and set information here.
          await fetch(`/api/stytch?session_token=${data.session_token}`);
          setIsAuthenticated(true);
          // history.push("/");
        } else {
          // history.push("/login");
        }
      } catch (err) {
        console.error("Error authenticating magic link", err);
        // history.push("/login");
      }
    };
    if (token) {
      setTimeout(authenticate, 5000);
      // authenticate();
    }
  }, [token, setIsAuthenticated]);

  return <IonText>Authenticating...</IonText>;
};
