import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
} from "@ionic/react";
import React, { FunctionComponent } from "react";

export const Home: FunctionComponent<{ isAuthenticated: boolean }> = ({
  isAuthenticated,
}) => {
  return (
    <IonPage>
      <IonHeader>
        <IonTitle>Juliet</IonTitle>
      </IonHeader>
      <IonContent>
        {isAuthenticated && (
          <IonText color="light">
            <h1>give me money</h1>
          </IonText>
        )}
        {!isAuthenticated && <IonButton routerLink="/login">Login</IonButton>}
      </IonContent>
    </IonPage>
  );
};
