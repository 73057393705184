import React from "react";
import { Stytch, StytchProps } from "@stytch/stytch-react";
import { IonContent, IonHeader, IonPage, IonTitle } from "@ionic/react";

export const Login = () => {
  const stytchProps: StytchProps = {
    loginOrSignupView: {
      products: ["emailMagicLinks"] as any,
      emailMagicLinksOptions: {
        loginRedirectURL: "http://localhost:3000/authenticate",
        loginExpirationMinutes: 30,
        signupRedirectURL: "http://localhost:3000/authenticate",
        signupExpirationMinutes: 30,
      },
    },
    style: {
      fontFamily: '"Helvetica New", Helvetica, sans-serif',
      width: "321px",
      primaryColor: "#0577CA",
      primaryTextColor: "#090909",
    },
    publicToken: "public-token-test-37d14567-95c3-43ff-ab48-f4c6f5d9fee0",
    callbacks: {
      onEvent: (data: any) => {
        if (data.eventData.type === "USER_EVENT_TYPE") {
          console.log(data);
        }
      },
      onSuccess: (data: any) => {
        console.log(data);
      },
      onError: (data: any) => {
        console.log(data);
      },
    },
  };

  return (
    <IonPage>
      <IonHeader>
        <IonTitle>Juliet</IonTitle>
      </IonHeader>
      <IonContent>
        <div className="Sign-in-container">
          <Stytch
            publicToken={stytchProps.publicToken}
            loginOrSignupView={stytchProps.loginOrSignupView}
            style={stytchProps.style}
            callbacks={stytchProps.callbacks}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};
